<template>
  <div class="title">权限管理</div>
  <el-card shadow="always">
    <div class="dept-header">
      <div class="dept-header__title">权限树配置</div>
      <!-- <div class="dept-header__btn" @click="confirmUpdate">确认修改</div> -->
    </div>

    <el-tree ref="treeDom" :data="data" class="dept-tree" node-key="id" default-expand-all :expand-on-click-node="false" style="padding: 20px 20px 0 20px;">
      <template #default="{ node, data }">
        <span class="custom-tree-node">
          <!-- <span v-if="!data.isDisabled">
            <el-input v-model="data.label" size="small" />
          </span> -->
          <span>{{ data.name }}</span>
          <span style="margin-left: 50px;">
            <a @click="edit(node, data)" style="vertical-align: middle;">
              <el-icon :size="20">
                <Edit />
              </el-icon>
            </a>
            <a @click="remove(node, data)" style="vertical-align: middle;margin-left:3px;">
              <el-icon :size="20">
                <Remove />
              </el-icon>
            </a>
            <a @click="append(data)" style="vertical-align: middle; margin-left:3px;">
              <el-icon :size="20">
                <CirclePlus />
              </el-icon>
            </a>
          </span>
        </span>
      </template>
    </el-tree>
    <el-icon :size="14" style="padding:0 5px 0 20px;vertical-align: -2px; color: blue;cursor:pointer;">
      <Plus />
    </el-icon>
    <span style="color: blue; cursor:pointer;" @click="appendFstNode">新增一级菜单</span>

  </el-card>

  <el-dialog title="修改权限" v-model="editDialog" :width="400">
    <el-form :model="form" ref="updateForm" :rules="rules" style="padding: 0 20px;">
      <el-form-item label="权限名称" label-width="80px" prop="resourceDesc">
        <el-input v-model="form.resourceDesc" autocomplete="off" maxlength="64"></el-input>
      </el-form-item>
      <el-form-item label="权限内容" label-width="80px" prop="resourceContent">
        <el-input v-model="form.resourceContent" type="textarea" autocomplete="off" maxlength="64"></el-input>
      </el-form-item>
      <el-form-item label="权限类型" label-width="80px" prop="resourceType">
        <el-select v-model="form.resourceType" placeholder="请选择一个类型" style="width: 100%;">
          <el-option v-for="item in resourceTypeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="editDialog = false">取 消</el-button>
        <el-button type="primary" @click="confirmNode">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog title="添加权限" v-model="addDialog" :width="400">
    <el-form :model="form" ref="addForm" :rules="rules" style="padding: 0 20px;">
      <el-form-item label="权限名称" label-width="80px" prop="resourceDesc">
        <el-input v-model="form.resourceDesc" autocomplete="off" maxlength="64"></el-input>
      </el-form-item>
      <el-form-item label="权限内容" label-width="80px" prop="resourceContent">
        <el-input v-model="form.resourceContent" type="textarea" autocomplete="off" maxlength="64"></el-input>
      </el-form-item>
      <el-form-item label="权限类型" label-width="80px" prop="resourceType">
        <el-select v-model="form.resourceType" placeholder="请选择一个类型" style="width: 100%;">
          <el-option v-for="item in resourceTypeList" :key="item" :label="item.label" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addDialog = false" size="small">取 消</el-button>
        <el-button type="primary" @click="confirmAddNode" size="small">确 定</el-button>
      </span>
    </template>
  </el-dialog>

</template>  

<script>
import { reactive, toRefs, ref, onMounted, getCurrentInstance } from 'vue';
import { CirclePlus, Remove, Edit, Plus } from "@element-plus/icons";
import { ElMessage } from 'element-plus';

export default {
  components: {
    CirclePlus,
    Remove,
    Edit,
    Plus
  },
  setup() {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      data: [],
      editDialog: false,
      addDialog: false,

      currentNode: null,
      currentData: null,

      form: {
        resourceDesc: '',
        resourceContent: '',
        resourceType: ''
      },
      curParentId: '0',
      curNodeId: '',

      resourceTypeList: [],

      rules: {
        resourceDesc: [{required: true, message: '请输入权限名称', trigger: 'blur'}],
        resourceContent: [{required: true, message: '请输入权限内容', trigger: 'blur'}],
        resourceType: [{required: true, message: '请选择权限类型', trigger: 'change'}]
      }
    })

    const addForm = ref();
    const updateForm =  ref();

    onMounted(() => {
      getTree();
      //获取权限类型枚举
      getResourceType();
    })

    const getTree = () => {
      proxy.$get('/cts/back/authTree/detail').then((res) => {
        if (res.code === '200') {
          state.data = res.data;
        }
      }).catch((err) => {

      });
    }

    const getResourceType = () => {
      proxy.$get('/cts/back/authTree/resouceType').then((res) => {
        if (res.resultState === '200') {
          state.resourceTypeList = res.data;
        }
      }).catch((err) => {
        
      });
    }

    const edit = (node, data) => {

      state.editDialog = true;

      state.currentNode = node;
      state.currentData = data;

      const parent = state.currentNode.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === state.currentData.id);
      const { content, id, name, type } = children[index];
      state.curNodeId = id;
      state.form = {
        resourceDesc: name,
        resourceContent: content,
        resourceType: type
      }
    }

    const appendFstNode = () => {

      state.addDialog = true;
      state.curParentId = '0';
      state.form = {
        resourceDesc: '',
        resourceContent: '',
        resourceType: ''
      }
    }

    const append = (data) => {
      state.addDialog = true;
      state.curParentId = data.id;
      state.form = {
        resourceDesc: '',
        resourceContent: '',
        resourceType: ''
      }
    }

    const remove = (node, data) => {
 
      let params = {
        resourceId: data.id
      }
      proxy.$post('/cts/back/authTree/delete', params).then((res) => {
        if (res.code === '500') {
          ElMessage({
            message: `${res.msg}`,
            type: 'warning',
          })
        } else if (res.code === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: 'success',
          })
          getTree();
        }
      }).catch((err) => {

      });

    }

    const confirmNode = () => {

      let params = {
        resourceContent: state.form.resourceContent,
        resourceDesc: state.form.resourceDesc,
        resourceId: state.curNodeId,
        resourceType: state.form.resourceType
      }
      updateForm.value.validate(valid => {
        if (valid) {
          proxy.$post('/cts/back/authTree/modify', params).then((res) => {
            if (res.code === '200') {
              ElMessage({
                message: `${res.msg}`,
                type: 'success',
              })
              state.editDialog = false;
              getTree();
            }
          }).catch((err) => {

          });
        }
      })

    }

    const confirmUpdate = () => {
      let params = state.data
      proxy.$post('/cts/back/authTree/modify', params).then((res) => {
        if (res.code === '200') {
          ElMessage({
            message: `${res.msg}`,
            type: 'success',
          })
        }
      }).catch((err) => {

      });
    }

    const confirmAddNode = () => {
      let params = {
        parentId: state.curParentId,
        resourceContent: state.form.resourceContent,
        resourceDesc: state.form.resourceDesc,
        resourceType: state.form.resourceType
      }
      addForm.value.validate(valid => {
        if (valid) {
          proxy.$post('/cts/back/authTree/add', params).then((res) => {
            if (res.code === '200') {
              ElMessage({
                message: `${res.msg}`,
                type: 'success',
              })
              state.addDialog = false;
              getTree();
            }
          }).catch((err) => {

          });
        }
      })
    }

    return {
      ...toRefs(state),
      append,
      remove,
      edit,
      confirmNode,
      confirmUpdate,
      appendFstNode,
      confirmAddNode,
      addForm,
      updateForm
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.dept-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__title {
    font-weight: bold;
  }
  &__btn {
    background: rgb(56, 162, 138);
    width: 100px;
    text-align: center;
    padding: 5px 0;
    border-radius: 3px;
    color: #fff;
    cursor: pointer;
  }
}
</style>